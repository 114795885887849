/* eslint-disable max-lines */
/* eslint-disable typescript-enum/no-enum */
/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any }
}

export type Address = {
  __typename?: 'Address'
  addressLine2?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  location?: Maybe<LocationDto>
  streetAddress?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type AgreedLegalDocument = {
  __typename?: 'AgreedLegalDocument'
  agreedAt: Scalars['DateTime']['output']
  agreedVersion: Scalars['Float']['output']
  choices: LegalDocumentChoiceResponseDto[]
  legalDocumentId: Scalars['String']['output']
}

export type AgreedLegalDocumentDto = {
  agreedAt: Scalars['DateTime']['input']
  agreedVersion: Scalars['Float']['input']
  choices: LegalDocumentChoiceResponseInput[]
  legalDocumentId: Scalars['String']['input']
}

export type AttachBuyerToPaymentTunnelResult = {
  __typename?: 'AttachBuyerToPaymentTunnelResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<PolicePaymentTunnel>
}

export type BeneficiaryInfos = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  language: Languages
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type BookingSlot = {
  __typename?: 'BookingSlot'
  endDate: Scalars['DateTime']['output']
  expiredAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  startDate: Scalars['DateTime']['output']
  status: BookingSlotStatus
  type: BookingSlotType
}

export type BookingSlotSingleResult = {
  __typename?: 'BookingSlotSingleResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<BookingSlot>
}

export enum BookingSlotStatus {
  Closed = 'closed',
  Open = 'open',
  Pending = 'pending'
}

export enum BookingSlotType {
  CheckUp = 'checkUp',
  ComplementaryExam = 'complementaryExam',
  FollowUp = 'followUp',
  Onzens = 'onzens'
}

export type BooleanResult = {
  __typename?: 'BooleanResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<Scalars['Boolean']['output']>
}

export type BuyerAddress = {
  city: Scalars['String']['input']
  /** ISO 3166-1 alpha-2 code */
  countryAlpha2: Scalars['String']['input']
  streetAddress: Scalars['String']['input']
  zipCode: Scalars['String']['input']
}

export type BuyerInfos = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  invoiceDetails?: InputMaybe<InvoiceDetails>
  language?: InputMaybe<Languages>
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type CenterAnalysisAppointmentDto = {
  __typename?: 'CenterAnalysisAppointmentDTO'
  address: Address
  bookingPhoneNumber: Scalars['String']['output']
  fakeProfile?: Maybe<FakeProfileDto>
  qrCode?: Maybe<Scalars['String']['output']>
  type: MetadataType
}

export type CreatePaymentTransactionResult = {
  __typename?: 'CreatePaymentTransactionResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<PolicePaymentTransaction>
}

export type DailyRecurrenceDto = {
  __typename?: 'DailyRecurrenceDTO'
  /** The duration of the recurrence expressed in ISO8601 (one-of with endDate and occurrences) */
  duration?: Maybe<Scalars['String']['output']>
  /** the end date of the recurrence expressed as a Date (with no time) in ISO8601 (one-of with duration and occurrences) */
  endDate?: Maybe<Scalars['String']['output']>
  /** the frequency of the recurrence */
  frequency: Frequency
  /** the interval between each occurrence of the recurrence (1 meaning each occurrence, 2 meaning one every two occurrences) */
  interval?: Maybe<Scalars['Float']['output']>
  /**
   * the number of occurrences of the recurrence (one-of with duration and endDate)
   * @deprecated Use occurrences instead of occurences
   */
  occurences?: Maybe<Scalars['Float']['output']>
  /** the number of occurrences of the recurrence (one-of with duration and endDate) */
  occurrences?: Maybe<Scalars['Int']['output']>
  /** the start date of the recurrence expressed as a Date (with no time) in ISO8601 */
  startDate: Scalars['String']['output']
  /** time at which events should be triggered on each occurrence. Expressed as a time in ISO8601 (at least one is required) */
  timeTable: Scalars['String']['output'][]
}

export type FakeProfileDto = {
  __typename?: 'FakeProfileDto'
  birthDate: Scalars['String']['output']
  firstName: Scalars['String']['output']
  gender: Gender
  lastName: Scalars['String']['output']
  sampleId: Scalars['String']['output']
}

export type FakeProfileWithMedicalProfileIdDto = {
  __typename?: 'FakeProfileWithMedicalProfileIdDto'
  birthDate: Scalars['String']['output']
  firstName: Scalars['String']['output']
  gender: Gender
  lastName: Scalars['String']['output']
  medicalProfileId: Scalars['String']['output']
  sampleId: Scalars['String']['output']
}

export type FetchOrganizationsResult = {
  __typename?: 'FetchOrganizationsResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<OrganizationConnection>
}

export type FollowupMeetingDto = {
  __typename?: 'FollowupMeetingDTO'
  address?: Maybe<Address>
  isRemoteAttendance?: Maybe<Scalars['Boolean']['output']>
  qrCode?: Maybe<Scalars['String']['output']>
  type: MetadataType
  /** @deprecated This link is not used anymore. */
  visioLink?: Maybe<Scalars['String']['output']>
}

export enum Frequency {
  Daily = 'daily',
  Monthly = 'monthly',
  Weekly = 'weekly'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GroupedBookingSlotResult = {
  __typename?: 'GroupedBookingSlotResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<GroupedBookingSlots[]>
}

export type GroupedBookingSlots = {
  __typename?: 'GroupedBookingSlots'
  bookingSlots: BookingSlot[]
  date: Scalars['String']['output']
}

export type Invitation = {
  __typename?: 'Invitation'
  id: Scalars['String']['output']
}

export type InvitationDto = {
  __typename?: 'InvitationDTO'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  organization?: Maybe<Organization>
  sentAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<InvitationStatus>
}

export type InvitationResult = {
  __typename?: 'InvitationResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<Invitation>
}

export enum InvitationStatus {
  Canceled = 'Canceled',
  Claimed = 'Claimed',
  Created = 'Created',
  Sent = 'Sent'
}

export type InvitationsResult = {
  __typename?: 'InvitationsResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<InvitationDto[]>
}

export type InviteMemberInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  language?: InputMaybe<Languages>
  lastName: Scalars['String']['input']
}

export type InvoiceDetails = {
  address: BuyerAddress
  organizationName?: InputMaybe<Scalars['String']['input']>
}

export type IsAdmin = {
  __typename?: 'IsAdmin'
  isAdmin: Scalars['Boolean']['output']
}

export type IsAdminResult = {
  __typename?: 'IsAdminResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<OrganizationManagerIsAdmin>
}

export enum LanguageCodeIso6391 {
  En = 'en',
  Fr = 'fr'
}

export enum Languages {
  EnUs = 'en_us',
  FrFr = 'fr_fr'
}

export enum LeadSourceType {
  Advertising = 'Advertising',
  Doctor = 'Doctor',
  Event = 'Event',
  Other = 'Other',
  Podcast = 'Podcast',
  Press = 'Press',
  RecommendationOfARelative = 'RecommendationOfARelative',
  SearchEngine = 'SearchEngine',
  SocialNetworks = 'SocialNetworks'
}

export type LegalDocumentChoiceResponseDto = {
  __typename?: 'LegalDocumentChoiceResponseDto'
  id: Scalars['String']['output']
  isChecked: Scalars['Boolean']['output']
  type: LegalDocumentChoiceTypeEnum
}

export type LegalDocumentChoiceResponseInput = {
  id: Scalars['String']['input']
  isChecked: Scalars['Boolean']['input']
  type: LegalDocumentChoiceTypeEnum
}

export enum LegalDocumentChoiceTypeEnum {
  ConsentRdUse = 'consentRDUse',
  Normal = 'normal'
}

export type LocationDto = {
  __typename?: 'LocationDTO'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
}

export enum MetadataType {
  CenterAnalysisAppointment = 'CenterAnalysisAppointment',
  FollowupMeeting = 'FollowupMeeting',
  Preparation = 'Preparation',
  RecommendationLinked = 'RecommendationLinked',
  ToolLinked = 'ToolLinked'
}

export type MonthlyRecurrenceDto = {
  __typename?: 'MonthlyRecurrenceDTO'
  /** The duration of the recurrence expressed in ISO8601 (one-of with endDate and occurrences) */
  duration?: Maybe<Scalars['String']['output']>
  /** the end date of the recurrence expressed as a Date (with no time) in ISO8601 (one-of with duration and occurrences) */
  endDate?: Maybe<Scalars['String']['output']>
  /** the frequency of the recurrence */
  frequency: Frequency
  /** the interval between each occurrence of the recurrence (1 meaning each occurrence, 2 meaning one every two occurrences) */
  interval?: Maybe<Scalars['Float']['output']>
  /**
   * the number of occurrences of the recurrence (one-of with duration and endDate)
   * @deprecated Use occurrences instead of occurences
   */
  occurences?: Maybe<Scalars['Float']['output']>
  /** the number of occurrences of the recurrence (one-of with duration and endDate) */
  occurrences?: Maybe<Scalars['Int']['output']>
  /** the start date of the recurrence expressed as a Date (with no time) in ISO8601 */
  startDate: Scalars['String']['output']
  /** time at which events should be triggered on each occurrence. Expressed as a time in ISO8601 (at least one is required) */
  timeTable: Scalars['String']['output'][]
}

export type Mutation = {
  __typename?: 'Mutation'
  attachInfosToPaymentTunnel: AttachBuyerToPaymentTunnelResult
  cancelMemberInvitation: InvitationResult
  contact: BooleanResult
  createPaymentTransaction: CreatePaymentTransactionResult
  /** Invite a B2B member only. */
  inviteMemberToOrganization: InvitationResult
  /** @deprecated Deprecated with new offer - ZD-6143 */
  registerOrganizationManager: OrganizationManagerResult
  submitWebsiteContactForm: BooleanResult
  submitWebsiteInterestedCompanyForm: BooleanResult
  /** Update the total number of vouchers for the organization */
  updateOrganizationVouchersCount: VouchersCountResult
}

export type MutationAttachInfosToPaymentTunnelArgs = {
  beneficiaryInfos: BeneficiaryInfos
  buyerInfos: BuyerInfos
  clientSecret: Scalars['ID']['input']
  shippingInfos?: InputMaybe<ShippingInfos>
}

export type MutationCancelMemberInvitationArgs = {
  invitationId: Scalars['ID']['input']
  organizationId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationContactArgs = {
  content: Scalars['String']['input']
}

export type MutationInviteMemberToOrganizationArgs = {
  invitation: InviteMemberInput
  organizationId: Scalars['ID']['input']
}

export type MutationRegisterOrganizationManagerArgs = {
  invitationId?: InputMaybe<Scalars['String']['input']>
}

export type MutationSubmitWebsiteContactFormArgs = {
  fields: WebsiteContactFormFields
  formId: Scalars['ID']['input']
  portalId: Scalars['ID']['input']
}

export type MutationSubmitWebsiteInterestedCompanyFormArgs = {
  fields: WebsiteInterestedCompanyFormFields
  formId: Scalars['ID']['input']
  portalId: Scalars['ID']['input']
}

export type MutationUpdateOrganizationVouchersCountArgs = {
  newQuantity: Scalars['Int']['input']
  organizationId: Scalars['ID']['input']
}

export type OneTimeEventFind = {
  __typename?: 'OneTimeEventFind'
  attendeeProfessionalIds: Scalars['String']['output'][]
  canceledAt?: Maybe<Scalars['DateTime']['output']>
  endDate: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  markedAsDoneAt?: Maybe<Scalars['DateTime']['output']>
  metadata: SerializedMetadataDto
  /** Owner ID of the event e.g. memberId of the owner of the event */
  ownerId: Scalars['String']['output']
  professionalAttendees: ProfessionalAttendee[]
  recurringEventId?: Maybe<Scalars['String']['output']>
  startDate: Scalars['DateTime']['output']
  title: Scalars['String']['output']
}

export type Organization = {
  __typename?: 'Organization'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type OrganizationDto = {
  __typename?: 'OrganizationDTO'
  address: Address
  companyRegistrationNumber: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
}

export type OrganizationManagerDto = {
  __typename?: 'OrganizationManagerDTO'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  organizationId?: Maybe<Scalars['String']['output']>
}

export type OrganizationManagerIsAdmin = {
  __typename?: 'OrganizationManagerIsAdmin'
  isAdmin: Scalars['Boolean']['output']
}

export type OrganizationManagerResult = {
  __typename?: 'OrganizationManagerResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<OrganizationManagerDto>
}

export type OrganizationResult = {
  __typename?: 'OrganizationResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<OrganizationDto>
}

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction'
  clientSecret: Scalars['ID']['output']
  partnerPaymentCustomerId: Scalars['ID']['output']
  paymentProviderPublicKey: Scalars['String']['output']
}

export type PaymentTunnel = {
  __typename?: 'PaymentTunnel'
  id: Scalars['ID']['output']
}

export type PolicePaymentTransaction = {
  __typename?: 'PolicePaymentTransaction'
  clientSecret: Scalars['ID']['output']
  paymentProviderPublicKey: Scalars['String']['output']
}

export type PolicePaymentTunnel = {
  __typename?: 'PolicePaymentTunnel'
  id: Scalars['ID']['output']
}

export type PreparationDto = {
  __typename?: 'PreparationDTO'
  link: Scalars['String']['output']
  type: MetadataType
}

export enum ProfessionType {
  Cardiologist = 'CARDIOLOGIST',
  CenterManager = 'CENTER_MANAGER',
  CustomerSuccess = 'CUSTOMER_SUCCESS',
  Doctor = 'DOCTOR',
  ItAdmin = 'IT_ADMIN',
  Kine = 'KINE',
  Manipulator = 'MANIPULATOR',
  MedicalAssistant = 'MEDICAL_ASSISTANT',
  Nurse = 'NURSE',
  Ophthalmologist = 'OPHTHALMOLOGIST',
  Orthoptist = 'ORTHOPTIST',
  Radiologist = 'RADIOLOGIST',
  Support = 'SUPPORT'
}

export type Professional = {
  __typename?: 'Professional'
  assignedMedicalProfiles: Scalars['String']['output'][]
  avatarId?: Maybe<Scalars['String']['output']>
  customSignatureId?: Maybe<Scalars['String']['output']>
  details: Scalars['JSONObject']['output']
  disabledAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  gender: ProfessionalGender
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
  role: Scalars['String']['output']
  spokenLanguages: LanguageCodeIso6391[]
}

export type ProfessionalAttendee = {
  __typename?: 'ProfessionalAttendee'
  disabledAt?: Maybe<Scalars['DateTime']['output']>
  firstName: Scalars['String']['output']
  gender?: Maybe<ProfessionalGender>
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  professionType?: Maybe<ProfessionType>
}

export enum ProfessionalGender {
  Female = 'FEMALE',
  Male = 'MALE',
  NonBinary = 'NON_BINARY'
}

export type Query = {
  __typename?: 'Query'
  fetchBookingSlotById: BookingSlotSingleResult
  fetchInvitations: InvitationsResult
  fetchOpenBookingSlots: GroupedBookingSlotResult
  /** @deprecated Should not be used anymore since license is not used by individual OM anymore */
  fetchOrganization: OrganizationResult
  /** Fetch the total number of vouchers for the organization, used or not */
  fetchOrganizationVouchersCount: VouchersCountResult
  fetchOrganizations: FetchOrganizationsResult
  fetchUser: OrganizationManagerResult
  fetchUserIsAdmin: IsAdminResult
  mandatoryGqlQuery: Scalars['String']['output']
  validateGiftCode: ValidateGiftCodeResult
}

export type QueryFetchBookingSlotByIdArgs = {
  bookingSlotId: Scalars['ID']['input']
}

export type QueryFetchInvitationsArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryFetchOpenBookingSlotsArgs = {
  types?: InputMaybe<BookingSlotType[]>
}

export type QueryFetchOrganizationVouchersCountArgs = {
  organizationId: Scalars['ID']['input']
}

export type QueryFetchOrganizationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type QueryValidateGiftCodeArgs = {
  giftCode: Scalars['String']['input']
}

export type RecommendationLinkedDto = {
  __typename?: 'RecommendationLinkedDTO'
  domain: Scalars['String']['output']
  recommendationId: Scalars['String']['output']
  recommendationType: RecommendationType
  type: MetadataType
}

export enum RecommendationType {
  Exercise = 'exercise',
  Lifestyle = 'lifestyle',
  Nutrition = 'nutrition',
  Sleep = 'sleep',
  StressManagement = 'stressManagement',
  Supplement = 'supplement',
  Therapeutic = 'therapeutic'
}

export type SerializedEventRecurrenceDto =
  | DailyRecurrenceDto
  | MonthlyRecurrenceDto
  | WeeklyRecurrenceDto

export type SerializedMetadataDto =
  | CenterAnalysisAppointmentDto
  | FollowupMeetingDto
  | PreparationDto
  | RecommendationLinkedDto
  | ToolLinkedDto

export type SerializedOneTimeEventDto = {
  __typename?: 'SerializedOneTimeEventDTO'
  attendeeProfessionalIds: Scalars['String']['output'][]
  canceledAt?: Maybe<Scalars['DateTime']['output']>
  endDate: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  markedAsDoneAt?: Maybe<Scalars['DateTime']['output']>
  metadata: SerializedMetadataDto
  /** Owner ID of the event e.g. memberId of the owner of the event */
  ownerId: Scalars['String']['output']
  recurringEventId?: Maybe<Scalars['String']['output']>
  startDate: Scalars['DateTime']['output']
  title: Scalars['String']['output']
}

export type SerializedRecurringEventDto = {
  __typename?: 'SerializedRecurringEventDTO'
  attendeeProfessionalIds: Scalars['String']['output'][]
  durationInMinutes: Scalars['Float']['output']
  effectiveEndDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  metadata: SerializedMetadataDto
  /** Owner ID of the event e.g. memberId of the owner of the event */
  ownerId: Scalars['String']['output']
  recurrence: SerializedEventRecurrenceDto
  title: Scalars['String']['output']
}

export type ShippingInfos = {
  address: BuyerAddress
  complementaryInfos?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

export type ToolLinkedDto = {
  __typename?: 'ToolLinkedDto'
  fastingType?: Maybe<Scalars['String']['output']>
  skippedMeal?: Maybe<Scalars['String']['output']>
  toolType: Scalars['String']['output']
  type: MetadataType
  weekDay?: Maybe<Scalars['String']['output']>
}

export type ValidateGiftCodeResult = {
  __typename?: 'ValidateGiftCodeResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<Scalars['Boolean']['output']>
}

export type VouchersCount = {
  __typename?: 'VouchersCount'
  total: Scalars['Float']['output']
}

export type VouchersCountResult = {
  __typename?: 'VouchersCountResult'
  reasons: Scalars['String']['output'][]
  result?: Maybe<VouchersCount>
}

export type WebsiteContactFormFields = {
  company?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  gclid?: InputMaybe<Scalars['String']['input']>
  isBrochureRequested?: InputMaybe<Scalars['Boolean']['input']>
  lastName: Scalars['String']['input']
  leadSource: LeadSourceType
  leadSourceComment?: InputMaybe<Scalars['String']['input']>
  message: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
}

export type WebsiteInterestedCompanyFormFields = {
  company: Scalars['String']['input']
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  gclid?: InputMaybe<Scalars['String']['input']>
  jobTitle: Scalars['String']['input']
  lastName: Scalars['String']['input']
  leadSource: LeadSourceType
  leadSourceComment?: InputMaybe<Scalars['String']['input']>
  message: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  potentialSubscriptionsInterest: Scalars['String']['input']
}

export enum Weekday {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type WeeklyRecurrenceDto = {
  __typename?: 'WeeklyRecurrenceDTO'
  /** The duration of the recurrence expressed in ISO8601 (one-of with endDate and occurrences) */
  duration?: Maybe<Scalars['String']['output']>
  /** the end date of the recurrence expressed as a Date (with no time) in ISO8601 (one-of with duration and occurrences) */
  endDate?: Maybe<Scalars['String']['output']>
  /** the frequency of the recurrence */
  frequency: Frequency
  /** the interval between each occurrence of the recurrence (1 meaning each occurrence, 2 meaning one every two occurrences) */
  interval?: Maybe<Scalars['Float']['output']>
  /**
   * the number of occurrences of the recurrence (one-of with duration and endDate)
   * @deprecated Use occurrences instead of occurences
   */
  occurences?: Maybe<Scalars['Float']['output']>
  /** the number of occurrences of the recurrence (one-of with duration and endDate) */
  occurrences?: Maybe<Scalars['Int']['output']>
  /** the start date of the recurrence expressed as a Date (with no time) in ISO8601 */
  startDate: Scalars['String']['output']
  /** time at which events should be triggered on each occurrence. Expressed as a time in ISO8601 (at least one is required) */
  timeTable: Scalars['String']['output'][]
  weekdays?: Maybe<Weekday[]>
}

/** Pagination connection for 'organization'. For more information, visit https://relay.dev/graphql/connections.htm */
export type OrganizationConnection = {
  __typename?: 'organizationConnection'
  /** Content of the page */
  edges: OrganizationEdge[]
  /** Metadata about the page */
  pageInfo: OrganizationPageInfo
}

/** Contains a single pagination node (an entity, organization) along with its cursor */
export type OrganizationEdge = {
  __typename?: 'organizationEdge'
  /** Cursor associated to the entity */
  cursor: Scalars['ID']['output']
  /** Entity (organization) itself */
  node: OrganizationDto
}

/** Contains information concerning presence of next or previous page as well as cursors to access them. */
export type OrganizationPageInfo = {
  __typename?: 'organizationPageInfo'
  /** Last cursor of the current page, same as edges.at(-1).cursor */
  endCursor?: Maybe<Scalars['ID']['output']>
  /** Indicates presence of next page, or possibility to paginate forward */
  hasNextPage: Scalars['Boolean']['output']
  /** Indicates presence of previous page, or possibility to paginate backward */
  hasPreviousPage: Scalars['Boolean']['output']
  /** First cursor of the current page, same as edges.at(0).cursor */
  startCursor?: Maybe<Scalars['ID']['output']>
}
